import "../scss/style.scss";

document.addEventListener("DOMContentLoaded", function () {
  try {
    $("#preloader").hide();
    new WOW({}).init();
  } catch (e) {

  }

  //Add in-view class to scoll-animation items
  var $animation_elements = $('.scroll_animation');
  var $window = $(window);

  function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = ( window_top_position + window_height );

    $.each($animation_elements, function () {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top;
      var element_bottom_position = ( element_top_position + element_height );

      //check to see if this current container is within viewport
      if (( element_bottom_position >= window_top_position ) &&
        ( element_top_position <= window_bottom_position )) {
        $element.addClass('in-view');
      } else {
        $element.removeClass('in-view');
      }
    });
  }

  if ($(window).width() > 576) {
    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll');
  }

  $("#contactForm").submit(function (e) {
    e.preventDefault();

    var $form = $(this);
    $("#contacts-btn")
      .addClass('disabled')
      .prop('disabled', true)
      .html("<div class='lds-dual-ring'></div>");

    $.post("https://hook.integromat.com/rm0ksgt9pcxlx43oa4ndawouavmzaod2", $form.serialize())
      .then(function () {
        $("#contacts-btn")
          .removeClass('disabled')
          .prop('disabled', false)
          .html("Send Message");

        window.location.href = "#";
        alert("Your request is submitted. We will contact you back soon. Thank you!");
      });
  });
});